import React, { useState, useEffect, useCallback } from "react";
import logo from "./assets/Logo-Stosa-no-border.png";
import footerLogo from "./assets/b4nextlogo.png";
import "./App.css";

function App() {
  const [showForm, setShowForm] = useState(false);
  const [hasRequestedCatalog, setHasRequestedCatalog] = useState(false);
  const [item, setItem] = useState(null);
  const [location, setLocation] = useState(null);
  const [mainMedia, setMainMedia] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isImageModalOpen, setImageModalOpen] = useState(false);
  const [currentGalleryIndex, setCurrentGalleryIndex] = useState(0);
  const [scanId, setScanId] = useState(null);
  const [connectLinkId, setConnectLinkId] = useState(null);
  const [emailConflict, setEmailConflict] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const [selectedDay, setSelectedDay] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [showConsultationForm, setShowConsultationForm] = useState(false);
  const [hasRequestedConsulting, setHasRequestedConsulting] = useState(false);
  const [showConsultingPopup, setShowConsultingPopup] = useState(false);

  // const addItemToUser = async (userId, itemId) => {
  //   try {
  //     const response = await fetch(
  //       `${process.env.REACT_APP_BASE_URL}/AddItemToUser?userId=${userId}&itemId=${itemId}`,
  //       {
  //         method: "PATCH",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     );

  //     if (!response.ok) {
  //       throw new Error("Errore durante l'associazione dell'item all'utente.");
  //     }

  //     const data = await response.json();
  //     console.log("Associazione completata con successo:", data);

  //     // alert("L'item è stato associato all'utente con successo.");
  //   } catch (error) {
  //     console.error("Errore durante l'associazione:", error);
  //     // alert("Errore durante l'associazione. Riprova più tardi.");
  //   }
  // };

  const days = Array.from({ length: 31 }, (_, i) => i + 1);
  const months = [
    "Gennaio",
    "Febbraio",
    "Marzo",
    "Aprile",
    "Maggio",
    "Giugno",
    "Luglio",
    "Agosto",
    "Settembre",
    "Ottobre",
    "Novembre",
    "Dicembre",
  ];
  const years = Array.from({ length: 2050 - 2024 + 1 }, (_, i) => 2024 + i);

  const handleCalendarToggle = () => {
    setShowCalendar((prev) => !prev);
  };

  const handleDateSelection = () => {
    if (selectedDay && selectedMonth !== null && selectedYear) {
      setShowCalendar(false);
    }
  };

  const executeScanWorkflow = async (qrParam, itemId) => {
    try {
      setLoading(true);
      const createScanResponse = await fetch(
        `${process.env.REACT_APP_BASE_URL}/scan`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ connectLinkId: qrParam, idItem: itemId }),
        }
      );

      if (!createScanResponse.ok) {
        throw new Error("Errore durante la creazione dello scan.");
      }

      const createScanData = await createScanResponse.json();
      const newScanId = createScanData.id;

      if (!newScanId) {
        throw new Error("ScanId o ConnectLinkId non trovati.");
      }

      setScanId(newScanId);
      setConnectLinkId(qrParam);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchItem = useCallback(
    async (qrParam) => {
      try {
        if (!item) {
          setLoading(true);
          const response = await fetch(
            `${process.env.REACT_APP_BASE_URL}/ConnectLink?connectLinkId=${qrParam}&Type=0`
          );
          if (!response.ok) throw new Error("Errore nel caricamento dei dati");

          const data = await response.json();
          if (!data.data) throw new Error("Nessun dato trovato");

          setItem(data.data.item);
          setLocation(data.data.location);
          executeScanWorkflow(qrParam, data.data.item.id);
          setMainMedia({ type: "image", url: data.data.item.posterImage });
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    },
    [item]
  );

  useEffect(() => {
    const hasRequestedConsultingList =
      JSON.parse(localStorage.getItem("requestedConsultations")) || [];
    const hasRequestedCatalogList =
      JSON.parse(localStorage.getItem("requestedCatalogs")) || [];

    const urlParams = new URLSearchParams(window.location.search);
    const qrParam = urlParams.get("QR");

    if (!qrParam) {
      setError("Il codice QR è assente o non valido.");
      setLoading(false);
      return;
    }

    setHasRequestedCatalog(hasRequestedCatalogList.includes(qrParam));

    setHasRequestedConsulting(hasRequestedConsultingList.includes(qrParam));

    fetchItem(qrParam);
  }, [fetchItem]);

  const handleMediaClick = (index) => {
    setCurrentGalleryIndex(index);
    setImageModalOpen(true);
  };

  const closeModal = () => {
    setImageModalOpen(false);
  };

  const goToPreviousImage = () => {
    setCurrentGalleryIndex((prevIndex) =>
      prevIndex === 0 ? item.gallery.length - 1 : prevIndex - 1
    );
  };

  const goToNextImage = () => {
    setCurrentGalleryIndex((prevIndex) =>
      prevIndex === item.gallery.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handleButtonClick = () => setShowForm(true);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setEmailConflict(false);
    const formData = new FormData(event.target);

    const requestBody = {
      name: formData.get("name"),
      surname: formData.get("surname"),
      email: formData.get("email"),
    };

    try {
      // const savedUserId = localStorage.getItem("userId");
      // const savedEmail = localStorage.getItem("userEmail");

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/postUserData?scanId=${scanId}&connectLinkId=${connectLinkId}&itemId=${item.id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...requestBody,
          }),
        }
      );

      if (response.status === 409) {
        setEmailConflict(true);
        throw new Error("L'email è già registrata.");
      }

      if (!response.ok) {
        throw new Error("Errore durante l'invio dei dati dell'utente.");
      }

      const result = await response.json();
      console.log("Risposta creazione utente:", result);

      const userId = result.user.id;

      localStorage.setItem("userId", userId);
      localStorage.setItem("userEmail", requestBody.email);

      const hasRequestedList =
        JSON.parse(localStorage.getItem("requestedCatalogs")) || [];
      hasRequestedList.push(connectLinkId);
      localStorage.setItem(
        "requestedCatalogs",
        JSON.stringify(hasRequestedList)
      );

      setHasRequestedCatalog(true);
      setShowForm(false);
      setShowPopup(true);
      return;

      // await addItemToUser(savedUserId, item.id);

      // const hasRequestedList =
      //   JSON.parse(localStorage.getItem("requestedCatalogs")) || [];
      // hasRequestedList.push(connectLinkId);
      // localStorage.setItem(
      //   "requestedCatalogs",
      //   JSON.stringify(hasRequestedList)
      // );

      // setHasRequestedCatalog(true);
      // setShowForm(false);
      // setShowPopup(true);
    } catch (error) {
      console.error("Errore durante l'invio dei dati:", error);
    }
  };

  const handleConsulting = async (event) => {
    event.preventDefault();
    setEmailConflict(false);
    const formData = new FormData(event.target);

    const requestBody = {
      name: formData.get("name"),
      surname: formData.get("surname"),
      email: formData.get("email"),
      day: selectedDay,
      month: selectedMonth,
      year: selectedYear,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/postUserData?scanId=${scanId}&connectLinkId=${connectLinkId}&itemId=${item.id}&useConsultingModel=true`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (response.status === 409) {
        setEmailConflict(true);
        throw new Error("L'email è già registrata.");
      }

      if (!response.ok) {
        throw new Error("Errore durante l'invio della consulenza.");
      }

      const result = await response.json();
      console.log("Risposta consulenza:", result);

      const hasRequestedConsultingList =
        JSON.parse(localStorage.getItem("requestedConsultations")) || [];
      hasRequestedConsultingList.push(connectLinkId);
      localStorage.setItem(
        "requestedConsultations",
        JSON.stringify(hasRequestedConsultingList)
      );
      setShowCalendar(false);
      setSelectedDay(null);
      setSelectedMonth(null);
      setSelectedYear(null);
      setHasRequestedConsulting(true);
      setShowConsultationForm(false);
      setShowConsultingPopup(true);
    } catch (error) {
      console.error("Errore durante l'invio dei dati della consulenza:", error);
    }
  };

  return (
    <div className="content-wrapper">
      <header className="App-header">
        <div
          href="https://www.stosacucine.com/it/"
          target="_blank"
          rel="noopener noreferrer"
        ></div>
      </header>

      <main className="main-content">
        <div className="App-title">
          <img
            src={logo}
            alt="Stosa Cucine Logo"
            className="header-logo fade-in"
          />
        </div>

        {loading ? (
          <div className="spinner"></div>
        ) : error ? (
          <div className="error-message">
            <p>{error}</p>
          </div>
        ) : (
          location &&
          item && (
            <div className="item-details">
              {mainMedia.type === "image" ? (
                <img
                  style={{ marginBottom: "30px" }}
                  src={mainMedia.url}
                  alt={item.title}
                  className="main-media"
                  onClick={() => handleMediaClick(0)}
                />
              ) : (
                <video src={mainMedia.url} controls className="main-media" />
              )}

              {!loading && !showForm && !error && (
                <>
                  {hasRequestedCatalog ? (
                    <div className="already-requested">
                      Hai già richiesto il catalogo.
                    </div>
                  ) : (
                    <div>
                      <div
                        className="request-catalog-button"
                        onClick={handleButtonClick}
                      >
                        Richiedi Catalogo
                      </div>
                    </div>
                  )}
                </>
              )}

              <div className="request-consultation-container">
                {hasRequestedConsulting ? (
                  <div className="already-requested">
                    Hai già richiesto la consulenza.
                  </div>
                ) : (
                  <button
                    className="request-catalog-button"
                    onClick={handleCalendarToggle}
                  >
                    Richiedi Consulenza
                  </button>
                )}

                {showCalendar && (
                  <div className="calendar-dropdown">
                    <div>
                      <select
                        value={selectedDay || ""}
                        onChange={(e) => {
                          setSelectedDay(e.target.value);
                          handleDateSelection();
                        }}
                      >
                        <option value="" disabled>
                          Giorno
                        </option>
                        {days.map((day) => (
                          <option key={day} value={day}>
                            {day}
                          </option>
                        ))}
                      </select>

                      <select
                        value={selectedMonth || ""}
                        onChange={(e) => {
                          setSelectedMonth(e.target.value);
                          handleDateSelection();
                        }}
                      >
                        <option value="" disabled>
                          Mese
                        </option>
                        {months.map((month, index) => (
                          <option key={index} value={index + 1}>
                            {month}
                          </option>
                        ))}
                      </select>

                      <select
                        value={selectedYear || ""}
                        onChange={(e) => {
                          setSelectedYear(e.target.value);
                          handleDateSelection();
                        }}
                      >
                        <option value="" disabled>
                          Anno
                        </option>
                        {years.map((year) => (
                          <option key={year} value={year}>
                            {year}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                )}

                {selectedDay && selectedMonth && selectedYear && (
                  <div
                    style={{
                      marginTop: "10px",
                      color: "white",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <p style={{ marginRight: "10px" }}>
                      Data selezionata: {selectedDay}/{selectedMonth}/
                      {selectedYear}
                    </p>
                    <button
                      style={{
                        backgroundColor: "#1C4171",
                        border: "none",
                        color: "white",
                        fontSize: "20px",
                        cursor: "pointer",
                        width: "30px",
                      }}
                      onClick={() => {
                        setSelectedDay(null);
                        setSelectedMonth(null);
                        setSelectedYear(null);
                        setShowCalendar(false);
                      }}
                      title="Cancella data"
                    >
                      ✖
                    </button>
                    <div
                      style={{
                        width: "30px",
                        border: "none",
                        color: "white",
                        fontSize: "20px",
                        cursor: "pointer",
                        backgroundColor: "#4CAF50",
                        marginLeft: "10px",
                      }}
                      onClick={() => setShowConsultationForm(true)}
                    >
                      ✔
                    </div>
                  </div>
                )}
              </div>

              <div className="item-description">
                <div className="label">
                  <span
                    style={{
                      fontFamily: "Monserrat",
                      fontSize: "30px",
                      marginBottom: "20px",
                    }}
                  >
                    {item.title}
                  </span>
                  <div></div>
                  <span>{item.subtitle}</span>
                </div>
                <div className="item-type">
                  <span className="label">Tipo:</span> {item.type}
                </div>
                <div className="location-info">
                  📍 <span className="label">Location:</span>{" "}
                  {location ? location.city : ""}, {location.country},{" "}
                  {location.street}
                </div>

                <div
                  style={{ marginTop: "20px" }}
                  className="item-title-description-text"
                >
                  Descrizione:
                </div>

                {item.description && (
                  <div className="item-description-text">
                    {item.description}
                  </div>
                )}
              </div>

              {showConsultingPopup && (
                <div className="popup">
                  <span
                    className="popup-close-icon"
                    onClick={() => setShowConsultingPopup(false)}
                  >
                    ✖
                  </span>
                  <div className="popup-title">Successo!</div>
                  <div className="popup-message">
                    La tua consulenza è stata richiesta con successo. Controlla
                    la tua email per ulteriori dettagli.
                  </div>
                  <button onClick={() => setShowConsultingPopup(false)}>
                    OK
                  </button>
                </div>
              )}

              {showPopup && (
                <div className="popup">
                  <span
                    className="popup-close-icon"
                    onClick={() => setShowPopup(false)}
                  >
                    ✖
                  </span>
                  <div className="popup-title">Successo!</div>
                  <div className="popup-message">
                    Il tuo catalogo è stato richiesto con successo. Controlla la
                    tua email per ulteriori dettagli.
                  </div>
                  <button onClick={() => setShowPopup(false)}>OK</button>
                </div>
              )}

              {item.gallery && item.gallery.length > 0 && (
                <div className="carousel-container">
                  {item.gallery.map((media, index) => (
                    <div
                      key={index}
                      className="carousel-image"
                      onClick={() => handleMediaClick(index)}
                    >
                      {media.type === "video" ? (
                        <video
                          src={media.url}
                          className="carousel-video-thumbnail"
                        />
                      ) : (
                        <img
                          src={media.url}
                          alt={`Gallery item ${index + 1}`}
                        />
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
          )
        )}

        {!loading && !showForm && !error && (
          <>
            {hasRequestedCatalog ? (
              <div className="already-requested">
                Hai già richiesto il catalogo.
              </div>
            ) : (
              <div>
                <div style={{ marginBottom: "10px" }}>
                  Richiedi il catalogo per saperne di più..
                </div>
                <div
                  className="request-catalog-button"
                  onClick={handleButtonClick}
                >
                  Richiedi Catalogo
                </div>
              </div>
            )}
          </>
        )}
        {showConsultationForm && (
          <>
            <div
              className="overlay"
              onClick={() => setShowConsultationForm(false)}
            >
              <div className="modal" onClick={(e) => e.stopPropagation()}>
                <span
                  className="close-icon"
                  onClick={() => setShowConsultationForm(false)}
                >
                  ✖
                </span>
                <p
                  style={{
                    color: "white",
                    fontFamily: "Playfair Display",
                    fontSize: "25px",
                  }}
                >
                  Compila il form e richiedi la consulenza
                </p>
                <form className="App-form" onSubmit={handleConsulting}>
                  <div className="form-row">
                    <input
                      type="text"
                      name="name"
                      placeholder="Nome *"
                      required
                    />
                    <input
                      type="text"
                      name="surname"
                      placeholder="Cognome *"
                      required
                    />
                    <input
                      type="email"
                      name="email"
                      placeholder="Email *"
                      required
                    />
                  </div>
                  {emailConflict && (
                    <div
                      className="error-message"
                      style={{ color: "red", marginTop: "10px" }}
                    >
                      L'email inserita è già registrata. Usa un'altra email.
                    </div>
                  )}
                  <button type="submit">Invia</button>
                </form>
                <small style={{ marginTop: "10px" }}>
                  Compila i campi obbligatori contrassegnati da *
                </small>
              </div>
            </div>
          </>
        )}
        {showForm && (
          <>
            <div className="overlay" onClick={() => setShowForm(false)}>
              <div className="modal" onClick={(e) => e.stopPropagation()}>
                <span className="close-icon" onClick={() => setShowForm(false)}>
                  ✖
                </span>
                <p
                  style={{
                    color: "white",
                    fontFamily: "Playfair Display",
                    fontSize: "25px",
                  }}
                >
                  Compila il form e ricevi il catalogo Cucine&Living Stosa
                </p>
                <form className="App-form" onSubmit={handleSubmit}>
                  <div className="form-row">
                    <input
                      type="text"
                      name="name"
                      placeholder="Nome *"
                      required
                    />
                    <input
                      type="text"
                      name="surname"
                      placeholder="Cognome *"
                      required
                    />
                    <input
                      type="email"
                      name="email"
                      placeholder="Email *"
                      required
                    />
                  </div>
                  {emailConflict && (
                    <div
                      className="error-message"
                      style={{ color: "red", marginTop: "10px" }}
                    >
                      L'email inserita è già registrata. Usa un'altra email.
                    </div>
                  )}
                  <button type="submit">Invia</button>
                </form>
                <small style={{ marginTop: "10px" }}>
                  Compila i campi obbligatori contrassegnati da *
                </small>
              </div>
            </div>
          </>
        )}

        {isImageModalOpen && item.gallery && (
          <div className="overlay" onClick={closeModal}>
            <div className="modal" onClick={(e) => e.stopPropagation()}>
              <button onClick={goToPreviousImage} className="modal-nav-button">
                ◀
              </button>
              <img
                src={item.gallery[currentGalleryIndex].url}
                alt={`Gallery item ${currentGalleryIndex + 1}`}
                className="modal-image"
              />
              <button onClick={goToNextImage} className="modal-nav-button">
                ▶
              </button>
            </div>
          </div>
        )}
      </main>

      <footer className="footer">
        © 2024 | Powered by{" "}
        <a
          href="https://www.b4next.it/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={footerLogo} alt="B4Next Logo" className="footer-logo" />
        </a>
      </footer>
    </div>
  );
}

export default App;
